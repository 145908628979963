import React from 'react';
import SVG from 'components/SVG';

const DeleteRowButtonRenderer = ({ api, node, data, list, setList }) => {
    const handleDelete = () => {
      const updatedList = list.filter(f => f.id !== data.id);
      setList({ type: 'remove', payload: data });
  
      setTimeout(() => {
        api.setRowData(updatedList);
      }, 0);
    };
  
    return (
      <span onClick={handleDelete}>
        <SVG
          icon="delete.svg#delete"
          iconWidth={24}
          iconHeight={24}
          iconClassName="button__icon button__icon-red"
        />
      </span>
    );
  };

export default DeleteRowButtonRenderer;
