import React, { useCallback, useMemo, useEffect, useState, useReducer, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Button from 'components/Button';
import _, { result, split } from 'lodash';
import { Modal, ModalBody, ModalFooter, ModalHeader, Container, Col, Row } from 'reactstrap';
import SVG from 'components/SVG';
import AggridContextMenu from 'components/AggridContextMenu';
import ClassificationDropdownEditor from 'components/ClassificationDropdownEditor';
import ExportToExcel from 'lib/ExportToExcel';
import CSVWizard from 'pages/Survey/components/CSVWizard';
import { generateuniqueIshId, listReducer, transposeObject, sum } from 'lib/helper';
import { postSearchApi } from 'api/chat';
import Loading from 'components/Loading';
import LoadingCellRenderer from 'components/LoadingCellRenderer';
import ArrayCellRenderer from 'components/ArrayCellRenderer';
import DraggableListCellRenderer from 'components/DraggableListCellRenderer';
import Table from 'components/Table';
import CollapsibleEvaluationAgGrid from 'components/CollapsibleAgGrid ';
import DeleteRowButtonRenderer from 'components/ButtonRenderer/DeleteRowButtonRenderer';

const BlueprintTab = (props) => {
  const {
    client, name, website, businessDetails, missionStatement,
    strategyArena, strategyVehicle, strategyDifferentiator,
    strategyStaging, strategyEconomicLogic, designCriteria,
    blueprint, setBlueprint
  } = props;

  const [isSelectingOrganizationClass, setIsSelectingBlueprint] = useState(false);
  const [selectedArchetype, setSelectedArchetype] = useState('');
  const [selectedOrientation, setSelectedOrientation] = useState('');

  const archetypes = [
    'Functional Structure',
    'Divisional Structure',
    'Matrix Structure',
    'Flat Structure',
    'Network Structure',
    'Team-based Structure',
    'Process-based Structure'
  ];

  const orientations = [
    'Product-Oriented',
    'Regionally Decentralized',
    'Function-Oriented',
    'Customer-Oriented',
    'Process-Oriented',
  ];

  useEffect(() => {
    if (blueprint?.archetype?.classification) {
      setSelectedArchetype(blueprint.archetype.classification);
    }
    if (blueprint?.orientation?.classification) {
      setSelectedOrientation(blueprint.orientation.classification);
    }
  }, [blueprint]);

  const recommendStructureType = async () => {
    if (isSelectingOrganizationClass) return;
    setIsSelectingBlueprint(true);

    const apiPayload = {
      'prompt_template': 'select_organization_type',
      'client': client,
      'business_name': name ? name : '',
      'business_website': website ? website : '',
      'mission_statement': missionStatement ? missionStatement : '',
      'industry': businessDetails.industry ? businessDetails.industry : '',
      'strategy_arena': strategyArena ? strategyArena : '',
      'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
      'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
      'strategy_staging': strategyStaging ? strategyStaging : '',
      'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
      'archetypes': archetypes,
      'orientations': orientations
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const recommendedClassification = apiResponse.message;

      try {
        setBlueprint(recommendedClassification);
        setSelectedArchetype(recommendedClassification.archetype.classification);
        setSelectedOrientation(recommendedClassification.orientation.classification);
      } catch (error) {
        console.error('Error parsing API response:', error);
      }

    } catch (error) {
      console.error('Error generating design criteria:', error);
    }
    setIsSelectingBlueprint(false);
  };

  const handleSelectArchetype = (archetype) => {
    setSelectedArchetype(archetype);
  };

  const handleSelectOrientation = (orientation) => {
    setSelectedOrientation(orientation);
  };

  return (
    <div className="define-tab">
      <div className="function-tab-header">
        <h1>Organizational Blueprint</h1>
      </div>

      <Row xs="8" className="align-items-left">
        <Col>
          <button
            onClick={() => recommendStructureType()}
            disabled={isSelectingOrganizationClass}
            className="button ms-auto"
          >
            Recommend a Blueprint
            <span className="material-symbols-outlined">neurology</span>
          </button>
        </Col>
      </Row>

      

      <Row>
        {/* Left Column: Organization Orientation */}
        <Col xs="6" className="align-items-left">
          <div className="content-wrapper">
            <div className="text-content">
              <h3>Orientation:</h3>
              <p>Your organizational Orientation reflects the strategic lens that informs how the organization prioritizes resources and decision-making. 
                Your orientation will influence along what axis the organization will be aligned and optimized for.</p>
            </div>
          </div>
          {blueprint?.orientation?.classification && 
            <Row xs="8" className="align-items-left">
              <Col>
                <h4>Recommendation: {blueprint.orientation.classification}</h4>
                <p>{blueprint.orientation.Strategic_challenge}</p>
                <p>{blueprint.orientation.classification_recommendation}</p>
              </Col>
            </Row>
          }
          <ul className="orientation-list">
            {orientations.map((orientation) => (
              <li
                key={orientation}
                onClick={() => handleSelectOrientation(orientation)}
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  backgroundColor: selectedOrientation === orientation ? '#11C684' : 'white',
                  border: '1px solid #ccc',
                  marginBottom: '5px',
                  borderRadius: '5px',
                  textAlign: 'center'
                }}
              >
                {orientation}
              </li>
            ))}
          </ul>
        </Col>

        {/* Right Column: Organization Archetype */}
        <Col xs="6" className="align-items-left">
          <div className="content-wrapper">
            <div className="text-content">
              <h3>Archetype:</h3>
              <p>Your organizational Archetype guides the structural design of the organization. 
                Your choice of archetype will influence how teams and units are arranged and interact.</p>
            </div>
          </div>
          {blueprint?.archetype?.classification && 
            <Row xs="8" className="align-items-left">
              <Col>
                <h4>Recommendation: {blueprint.archetype.classification}</h4>
                <p>{blueprint.archetype.Strategic_challenge}</p>
                <p>{blueprint.archetype.classification_recommendation}</p>
              </Col>
            </Row>
          }
          <ul className="classification-list">
            {archetypes.map((classification) => (
              <li
                key={classification}
                onClick={() => handleSelectArchetype(classification)}
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  backgroundColor: selectedArchetype === classification ? '#11C684' : 'white',
                  border: '1px solid #ccc',
                  marginBottom: '5px',
                  borderRadius: '5px',
                  textAlign: 'center'
                }}
              >
                {classification}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default BlueprintTab;
