import React, { useEffect, useReducer, useState } from 'react';
import { Modal, ModalFooter, ModalBody } from 'reactstrap';
import OrgChartComponent from 'components/OrgChart';
import Loading from 'components/Loading';
import Button from 'components/Button';
import { postSearchApi } from 'api/chat';
import { listReducer } from 'lib/helper';


const StructureTab = (props) => {
    const { client, name, website, businessDetails, missionStatement, 
      strategyArena, strategyVehicle, strategyDifferentiator, 
      strategyStaging, strategyEconomicLogic, designCriteria, blueprint, functions, organizationUnits, setOrganizationUnits, RaciMatrix } = props;
  const [orgChartData, setOrgChartData] = useState([]);
  const [isLoading, setIsLoading] = useReducer(listReducer, []);
  const [isGeneratingUnits, setIsGeneratingUnits] = useState(false);
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);

  const getReportingTo = (reportingTo) => {
    if (!reportingTo) {
      return null;
    }
    const parentUnit = organizationUnits.find((unit) => String(unit.abbreviation) === String(reportingTo));
    return parentUnit ? parentUnit.id : null;  // Return the nodeId, not the object
  };
  
  useEffect(() => {
    const buildOrgChartDataset = (units) => {
      let dataset = [];
      if (!units) {
        return dataset;
      }
      try {
        units.forEach((unit) => {
          const node = {
            nodeId: unit.id,
            parentNodeId: getReportingTo(unit.reporting_to),
            employee_number: '',
            name: unit.name,
            function: unit.core_functions.map(obj => obj.name).join(', '),
            role: '',
            unit: unit.abbreviation,
            borderWidth:1,
            borderRadius:5,
            borderColor:{
              red:15,
              green:140,
              blue:121,
              alpha:1
            },
            backgroundColor:{
              red:51,
              green:182,
              blue:208,
              alpha:1
            },
            nodeIcon:{
              icon:'https://www.freeiconspng.com/img/1682',
              size:30
            },
            connectorLineColor:{
              red:220,
              green:189,
              blue:207,
              alpha:1
            },
            connectorLineWidth:5,
            dashArray:'',
            expanded:false,
            directSubordinates:getDirectSubordinates(unit),
            totalSubordinates:getTotalSubordinates(unit),
          };
          dataset.push(node);
        });
      } catch (error) {
        //console.error('Error building org chart dataset:', error);
      }
      return dataset;
    }

    setOrgChartData(buildOrgChartDataset(organizationUnits));
  }, [organizationUnits]);


  const getDirectSubordinates = (unit) => {
    if (!unit) {
      return 0;
    }
    const directSubordinates = organizationUnits.filter((emp) => String(emp.reporting_to) === String(unit.abbreviation));
    return directSubordinates.length; // + (unit.fte-1);
  }

  const getTotalSubordinates = (unit) => {
    if (!unit) {
      return 0;
    }
    const directSubordinates = organizationUnits.filter((emp) => String(emp.reporting_to) === String(unit.abbreviation));
    let totalSubordinates = directSubordinates.length; // + (unit.fte-1);
    directSubordinates.forEach((emp) => {
      totalSubordinates += getTotalSubordinates(emp);
    });
    return totalSubordinates;
  }

  function onNodeClick(node) {
    setIsLoading({ type: 'add_to_list', payload: node.data.unit});

    const loadingNode = {
      nodeId: `${node.data.nodeId}-loading`,
      parentNodeId: node.data.nodeId,
      name: 'Loading...',
      function: '',
      unit: '',
      role: '',
      employee_number: '',
      borderWidth: 1,
      borderRadius: 5,
      borderColor: { red: 128, green: 128, blue: 128, alpha: 0.5 }, // Grey color
      backgroundColor: { red: 220, green: 220, blue: 220, alpha: 0.5 }, // Light grey background
      nodeIcon: { icon: 'loading-icon-url', size: 30 }, // Optional loading icon
      connectorLineColor: { red: 220, green: 189, blue: 207, alpha: 1 },
      connectorLineWidth: 5,
      expanded: true,
      directSubordinates: 0,
      totalSubordinates: 0,
    };

    setOrgChartData((prevData) => [...prevData, loadingNode]);

    const parentUnit = organizationUnits.find((unit) => unit.abbreviation === node.data.unit);
    setWarningModalIsOpen(parentUnit);
  }

  const generateSubUnits = async (parentUnit) => {
      if (isGeneratingUnits) return;
      //setIsGeneratingUnits(true);
      //const blankRow = addNewRow();
      //setIsLoading({ type: 'add_to_list', payload: blankRow.id });
  
      const apiPayload = {
          'prompt_template': 'generate_organization_sub_units',
          'client': client,
          'business_name': name ? name : '', 
          'business_website': website ? website : '',
          'mission_statement': missionStatement ? missionStatement : '',
          'industry': businessDetails.industry ? businessDetails.industry : '',
          'strategy_arena': strategyArena ? strategyArena : '',
          'strategy_vehicle': strategyVehicle ? strategyVehicle : '',
          'strategy_differentiators': strategyDifferentiator ? strategyDifferentiator : '',
          'strategy_staging': strategyStaging ? strategyStaging : '',
          'strategy_economic_logic': strategyEconomicLogic ? strategyEconomicLogic : '',
          'design_criteria': designCriteria ? designCriteria : [],
          'functions': functions ? functions : [],
          'organization_units': organizationUnits ? organizationUnits : [],
          'parent_unit': parentUnit,
      };
  
      try {
        const apiResponse = await postSearchApi(apiPayload);
        const generatedUnits = apiResponse.message;
  
        try {
          setOrganizationUnits({ type: 'extend', payload: generatedUnits } );
        } catch (error) {
            console.error('Error parsing API response:', error);
        }
  
      } catch (error) {
          console.error('Error generating organization sub units:', error);
      }
      setIsGeneratingUnits(false);
      //setIsLoading({ type: 'remove_from_list', payload: blankRow.id });
      //setOrganizationUnits({ type: 'remove', payload: blankRow });
    };

  return (
    <div className='diagram-area'>
      <OrgChartComponent 
        onNodeClick={onNodeClick}
        data={orgChartData} 
      />
      {warningModalIsOpen !== false && (
        <Modal isOpen={warningModalIsOpen !== false} toggle={() => setWarningModalIsOpen(false)}>
          <ModalBody className='text-center p-3'>
            <div className="popup-warning-header">
              Do you want to generate sub-units for <br />{warningModalIsOpen.name}?
            </div>
          </ModalBody>
          <ModalFooter>
            <button onClick={() => { 
              setWarningModalIsOpen(false)
              generateSubUnits(warningModalIsOpen)
            }} className="button ms-auto">
              Generate sub-units
              <span className="material-symbols-outlined">
                neurology
              </span>
            </button>
            <Button
              text="Cancel"
              onClick={() => setWarningModalIsOpen(false)}
              className="button ms-3"
            />
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
}

export default StructureTab;
